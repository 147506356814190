body {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
.App {
  margin-top: 150px;
}
a, button {
  cursor: pointer;
}
img {
  width: 300px;
  margin-bottom: 30px;
}
button {
  background: white;
  border: 2px solid black;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: .3s ease;
  margin-top: 30px;
}
button:hover {
  background: #8bca61;
  border: 2px solid black;
  padding: 10px 20px;
}

@media only screen and (max-width: 600px) {
  .App {
    margin-top: 0;
  }
}